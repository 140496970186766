.about_banner_conrainer {
    background-image: url("../../../assets/images/wallpaper.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    overflow: hidden;
    height: calc(100vh);
    /* margin-top: - 124.19px; */
    /* transform: translateY(-124.19px); */
    position: relative;
    margin-top:-160.19px;
    scroll-behavior: smooth;
}

.about_banner_text {
    width: fit-content;
    position: absolute;
    right: 0px;
    top:20%;
    text-align: center;
    margin-top: 150px;
}

.about_banner_text h2 {
    color: #eb741c !important;
}


.about_banner_text h2:first-of-type {
    font-size: 40px;
    font-weight: bold;
    margin: 0px !important;
    padding: 0px !important;
}

.about_banner_text h2:last-of-type {
    font-size:  80px ;
    font-weight: bolder;
    margin: 0px !important;
    padding: 0px !important;
}

.about_banner_text p {
    max-width: 70%;
    width: 100%;
    margin: 40px auto;
    font-weight: bold;
}

.scroll_arrow {
    /* background-color: #000; */
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: 20px;
    cursor: pointer;
    transition: .2s ease;
}

.scroll_arrow svg {
    /* color: white; */
}

.scroll_arrow svg:hover{
    transform: scale(1.1);
    fill: #383838;
}







/* //------------------- */



/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .about_banner_conrainer {
        background-image: url("../../../assets/images/tallPreviews.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        overflow: hidden;
        /* height: auto; */
        /* margin-top: - 124.19px; */
        /* transform: translateY(-124.19px); */
        position: relative;
        /* margin-top: -148.19px; */
    }
  

    .about_banner_text {
        width: fit-content;
        right: 50%;
        transform: translateX(50%);
        top:30px;

    }



    .about_banner_text h2:first-of-type {
        font-size: 40px;
        font-weight: bold;
        margin: 0px !important;
        padding: 0px !important;
    }
    
    .about_banner_text p {
        max-width: 80%;
    }

    .scroll_arrow{
    bottom: 40px;
    transform: scale(1.2)

    }
  
  
  
    }
    
    /* Medium devices (tablets, less than 992px) */
    @media (min-width: 768px) and (max-width: 991.98px) {

  
  
    
    }
    
    /* Extra large devices (large desktops, less than 1400px) */
    @media (max-width: 1399.98px) {
    }
    
    /* Extra extra large devices (larger desktops, less than 1600px) */
    @media (max-width: 1599.98px) {
    }
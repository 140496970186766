
html, body { 
  scroll-behavior: smooth !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.toastCon{
  z-index: 999999999999009999999 !important;
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.text_orange{
  color: #EE701E !important;
}

.bg_orange{
  background-color: #EE701E;
}




.modal_buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.modal_buttons .confirm_button,
.modal_buttons .cancel_button{
  border: none;
  outline: none;
  padding: 4px 10px;
  font-size: 13px;
  color: #fff;
  background-color: #2C9FAF !important;
  border-radius: 5px;
}

.modal_buttons .cancel_button{
  
  background-color: #858796 !important;
}

.modal_buttons .confirm_button:hover{
  background-color: #24818e !important;
  
}
.modal_buttons .cancel_button:hover{
  background-color: #68697a !important;

}


.modal_tabs{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bababa;
  margin-block: 20px;

}

.modal_tab{
  padding: 5px 10px;
  border-radius: 6px 6px 0 0 ;
  background-color: #fff;
  color: #7b7b7b;
  cursor: pointer;
}

.modal_tab.active{
  border: 1px solid #bababa;
  border-bottom: none;
  transform: translateY(1px);

}

.modal_tab:hover{
  border: 1px solid #d6d6d6;
  border-bottom: none;
  color: #045476;
  
}
.cart_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.329);
  z-index: 9999999999999999999999;
  transform: translateX(100%);
}
.cart_container.open {
  transform: translateX(0);
}

.cart_container .cart_contnet {
  position: fixed;
  width: 450px;
  padding: 20px;
  height: 100%;
  background-color: var(--main-color);
  right: 0;
  overflow: auto;
  z-index: 999999999999999;
  transform: translateX(100%);
  transition: 0.3s ease;
}

.cart_container.open .cart_contnet {
  transform: translateX(0);
}

.cart_container .cart_contnet .cart_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart_container .cart_contnet .cart_header h2 {
  color: #fff;
  font-weight: bolder;
}

.close_btn_cart {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  cursor: pointer;
}

.cart_container .cart_prods {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  position: relative;
  gap: 20px;
}

.cart_container .cart_prods .remove_from_cart {
  position: absolute;
  top: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(235, 44, 44);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
}

.cart_prod {
  padding: 20px;
  background-color: var(--main-color);
  display: flex;
  gap: 30px;
}

.cart_prod .prod_image {
  width: 100px;
  height: 100px;
  object-fit: fill;
  background-color: #fff;
}

.cart_prod .prod_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .cart_container .cart_contnet {
    width: 100%;
  }
  .cart_prod {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}

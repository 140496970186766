.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.324);
  display: flex;
  justify-content: center;
  transform: scale(0);
  opacity: 0;
  transition:
    transform 0.3s,
    opacity 0.3s;
  z-index: 100000000000000;
  overflow-y: auto;
  overflow-x:hidden ;
  padding: 30px;

}

.modal-overlay.noAnimation {
  transition: all 0s !important;
}

.modal-overlay.noOverLay {
  background: transparent;
}

.modal-overlay.show {
  opacity: 1;
  transform: scale(1);
}

.custom_modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: translateY(20px);
  opacity: 0;
  box-shadow: 0px 0px 10px #000000af;
  transition: all 0.3s ease-in-out;
  height: fit-content;
}

.modal-overlay.noAnimation .custom_modal {
  transition: all 0s !important;
}

.custom_modal.show {
  transform: translateY(0);
  opacity: 1;
}

.modal-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: bold;
  color: #454545c5;
  cursor: pointer;
}

.modal-close-button:hover {
  color: #313131ea;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  gap: 10px;
}

.modal-header h5 {
  margin: 10px 0;
  font-size: 18px;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.modal-buttons button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-buttons .modal-confirm-button {
  background-color: #056d7f;
  color: #fff;
}

.modal-buttons .modal-cancel-button {
  background-color: #f44336;
  color: #fff;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
 
  .petsCards{
    grid-template-columns: repeat(1, 1fr);
  }
  

  .modal-overlay {
    padding: 0;
  }
  
  }
  
  /* Medium devices (tablets, less than 992px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }
  
  /* Large devices (desktops, less than 1200px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
  }
  
  /* Extra large devices (large desktops, less than 1400px) */
  @media (max-width: 1399.98px) {
  }
  
  /* Extra extra large devices (larger desktops, less than 1600px) */
  @media (max-width: 1599.98px) {
  }
  
  
  

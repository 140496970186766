.no_need_container {
    background-color: #eb741c;
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.no_need_container .nn_title {
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no_need_container .nn_title p {
    font-size: 50px;
    text-align: center;
    font-weight: 600;
    margin: 0;
    margin: 0;
}

.no_need_container .nn_title p:first-child {
    color: #FFF !important;
}

.no_need_container .nn_title p:last-child {
    color: #000;
}

.no_need_page {
    margin: 0px auto;
    max-width: 800px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    gap: 40px;
    padding: 20px;
}  

.no_need_page p {
    color: white;
    font-size: 13px;
    font-weight: 600;
        
}

.no_need_page h4 {
    color: #000;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bolder;
}

.no_need_page .first-text {
    padding: 0px !important;
    font-size: 15px;
    margin: 0px !important;
}

@media (max-width:820px) {
 .no_need_page {
    grid-template-columns: 1fr;
 }   

 .no_need_page img {
    margin: 0px auto;
    /* width: 100%; */
 }

 .no_need_page div {
    margin:10px 20px ;
 }
}

@media (max-width:808px) {
    .nn_title {
        width: 90% ;
    }
   .nn_title p {
    font-size: 30px !important; 
   }   
}

@media (max-width:606px) {
    .nn_title {
        width: 100%;
        text-align: center;
    }
}

@media (max-width:560px) {
 .nn_title p {
    font-size: 25px !important;
 }   

 .no_need_page h4 {
    margin-bottom: 30px;
 }
}

@media (max-width:400px) {
    .nn_title p {
        font-size: 23px !important;
    }

    .no_need_page {
        padding: 10px;
    }
}